import logo from './savagekinglogo-tr.png';
import './App.css';
import Welcome from './Welcome.js';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="Savage King" />
        <Welcome />
      </header>
    </div>
  );
}

export default App;
