import { useCookies } from 'react-cookie';
import queryString from 'query-string';
import { useState, useEffect } from "react";

export default function Welcome() {
    const [msg, setMsg] = useState(null);
    const [cookies, setCookie] = useCookies(['launcherauth']);

    useEffect(() => {
        const parsed = queryString.parse(window.location.search);
        
        if ('launcherauth' in parsed) {
            fetch("/rest/auth/", {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authentication': parsed.launcherauth,
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    // 12 means we are waiting
                    if (data["code"] === 12) {
                        setCookie('launcherauth', parsed.launcherauth);
                        console.log(parsed.launcherauth);
                        window.location.href = "https://discord.com/oauth2/authorize?client_id=1258795456448823296&response_type=code&redirect_uri=https%3A%2F%2Flauncher.savageking.io%2F&scope=guilds.members.read+identify+guilds"
                    }
                })
                .catch((error) => console.log(error));
        }
        else if ('code' in parsed) {
            fetch("/rest/auth/finish", {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authentication': cookies.launcherauth,
                    'DiscordToken': parsed.code,
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    if (data["code"] === 0) {
                        setMsg("You can now close this tab and get back to Savage Launcher :3")
                    }
                    else {
                        setMsg("Something went wrong. Code " + data["code"]);
                    }
                })
                .catch((error) => console.log(error));
        }
      
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); // useEffect

    return (
        <p>{msg}</p>
    )
}
